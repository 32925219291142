var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "miniTable" },
    [
      _c(
        "el-table",
        {
          attrs: {
            stripe: "",
            data: _vm.tableData,
            height: "calc(100vh - 315px)"
          },
          on: { "selection-change": _vm.changeSelect }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "车队",
              "show-overflow-tooltip": true,
              width: "200px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmType",
              label: "报警名称",
              "show-overflow-tooltip": true,
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.alarmSpecies === "D0008"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dictObj.D0008Obj[Number(scope.row.alarmType)]
                            )
                          )
                        ])
                      : _vm._e(),
                    scope.row.alarmSpecies === "D0009"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dictObj.D0009Obj[Number(scope.row.alarmType)]
                            )
                          )
                        ])
                      : _vm._e(),
                    scope.row.alarmSpecies === "D0010"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dictObj.D0010Obj[Number(scope.row.alarmType)]
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "attention", label: "是否关注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(scope.row.attention === 1 ? "是" : "否"))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmSource", label: "报警来源" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dictObj.alarmSourceObj[
                          Number(scope.row.alarmSource)
                        ]
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: "最后更新时间",
              width: "180",
              "show-overflow-tooltip": true,
              formatter: _vm.formatTime
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "更新人" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$store.state.menu.nowMenuList.indexOf("编辑") >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }