<template>
  <div class="alarmEdit">
    <div class="search-area">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="true"
        label-width="126px"
        label-position="left"
        class="demo-form-inline dialog-form"
      >
        <!-- 是否开启提醒 -->
        <el-form-item label="产生时开启提醒：" style="width:100%;">
          <el-switch
            v-model="form.popup"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#c7c8c9"
            @change="popChange"
          ></el-switch>
          <!-- 提醒方式 -->
          <div style="display:inline-block;margin-left:12px;">
            <span>提醒方式:</span>
            <!-- 不开启提醒时，禁用掉提醒方式 -->
            <el-select
              clearable
              :disabled="!form.popup"
              v-model="form.popupType"
              placeholder="请选择提醒方式"
            >
              <el-option
                v-for="item in dictList.upgradeRemindList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
            <!-- 输入电话号码 -->
            <span v-show="form.popupType==3">
              输入手机号:
              <el-input
                v-model.trim="form.remindPhones"
                style="width:200px;"
                placeholder="手机号用逗号隔开"
              ></el-input>
            </span>
          </div>
        </el-form-item>
        <!-- 产生时是否声音提醒 -->
        <!-- <el-form-item label="产生时声音提醒：">
          <el-switch
            v-model="form.voice"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#c7c8c9"
            @change="voiceChange"
          ></el-switch>
        </el-form-item>-->
        <!-- 声音类型 -->
        <!-- <el-form-item label="声音类型：">
          <el-select
            @change="changeVoice"
            :disabled="!form.voice"
            v-model="form.voiceType"
            placeholder
          >
            <el-option
              v-for="item in dictList.musicTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <!-- 超时时间 -->
        <el-form-item label="超时时间:" style="width:100%;">
          <el-input v-model.trim="form.timeoutLimit" style="width:200px;" prop="timeoutLimit"></el-input>
          <span style="margin:0 10px;">分钟未处理，提醒方式</span>
          <el-select clearable v-model="form.timeoutRemind" placeholder>
            <el-option
              v-for="item in dictList.upgradeRemindList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
          <span v-show="form.timeoutRemind==3">
            输入手机号:
            <el-input
              v-model.trim="form.timeoutRemindPhones"
              style="width:200px;"
              placeholder="手机号用逗号隔开"
            ></el-input>
          </span>
        </el-form-item>
        <!-- 报警产生时是否系统自动处理 -->
        <el-form-item label="是否系统自动处理：">
          <el-select v-model="form.disposeType" placeholder="请选择" @change="changeType">
            <el-option
              v-for="item in automaticDealList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 处理方式 -->
        <el-form-item label="处理方式：" prop="disposeWay" v-show="dealShow">
          <el-select clearable v-model="form.disposeWay" placeholder="请选择">
            <el-option
              v-for="item in dictList.disposeWayList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 报警确认 -->
        <el-form-item label="报警确认：" prop="alarmConfirm" v-show="dealShow">
          <el-select clearable v-model="form.alarmConfirm" placeholder="请选择">
            <el-option
              v-for="item in dictList.policeConfirmedList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 警报播报内容 -->
        <el-form-item label="自动处理内容：" prop="disposeContent" v-if="dealShow">
          <el-input type="textarea" v-model="form.disposeContent"></el-input>
        </el-form-item>
      </el-form>
      <!-- <audio v-for="(item, index) in audioType" :id="item.id" :key="index" :src="item.src" muted></audio> -->
    </div>
  </div>
</template>

<script>


export default {
  props: {
    itemData: {
      type: String
    },
    editId: {
      type: Number
    },
    dictList: {
      type: Object
    },
    dictObj: {
      type: Object
    }
  },
  data () {
    let checkRangeNumber = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (typeof value !== 'number' || value < 0 || value > 1440) {
          callback(new Error('请输入0-1440的整数'))
        } else {
          callback()
        }
      }
    }
    return {
      form: {},
      audioType: [
        { id: 'audio1', src: '/static/static1/audio/alarmVoice(1).mp3' },
        { id: 'audio2', src: '/static/static1/audio/alarmVoice(2).mp3' },
        { id: 'audio3', src: '/static/static1/audio/alarmVoice(3).mp3' },
        { id: 'audio4', src: '/static/static1/audio/alarmVoice(4).mp3' },
        { id: 'audio5', src: '/static/static1/audio/alarmVoice(5).mp3' }
      ],
      remindType: [
        { dictValue: '弹窗', dictCode: '1' },
        { dictValue: '数字消息', dictCode: '2' },
        { dictValue: '屏幕闪烁', dictCode: '3' },
      ],//提醒方式
      voiceType: [
        { dictValue: '声音类型1', dictCode: '1' },
        { dictValue: '声音类型2', dictCode: '2' }
      ],//声音类型
      automaticDealList: [
        { dictValue: '是', dictCode: '1' },
        { dictValue: '否', dictCode: '0' }
      ],//是否自动处理
      rules: {
        timeoutLimit: [{ validator: checkRangeNumber, trigger: 'change' }],
        disposeWay: [
          { required: true, message: '请选择处理方式', trigger: 'blur' }
        ],
        disposeContent: [
          { required: true, message: '请填写处理描述', trigger: 'change' }
        ],
        alarmConfirm:[
        { required: true, message: '请选择报警确认', trigger: 'blur' }
        ]
      },
      dealShow:false,
    }
  },
  created () { },

  mounted () {
    if (this.editId) {
      this.form = JSON.parse(this.itemData)
      this.dealShow = this.form.disposeType=='1'?true:false;
      this.form.popupType += '';
      // this.form.voiceType += '';
      this.form.timeoutRemind += '';
      this.form.disposeType += '';
      this.form.alarmConfirm += '';
      this.form.disposeWay += '';
      
    } else {
      this.form = {
        popup: '',//是否开启提醒
        popupType: '',//提醒方式
        voice: '',//是否语音提示
        // voiceType: '',//声音提醒类型
        timeoutLimit: '',//超过未及时处理分钟数（未及时处理报警判断阀值）
        timeoutRemind: '',//超时提醒方式
        disposeType: '',//处理类型
        alarmConfirm: '',//报警确认
        disposeWay: '',//处理方式
        disposeContent: '',//处理内容
      }
    }
  },

  methods: {
    changeType(v){
      this.dealShow = v==='1'?true:false;
    },
    /* 改变声音试听 */
    changeVoice (v) {
      var audio = document.getElementById(`audio${v}`)
      audio.muted = false
      audio.play()
    },
    popChange (val) {
      if (!val) {
        this.form.popupType = ''
      }
    },
    voiceChange (val) {
      if (!val) {
        this.form.voiceType = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form--inline .el-form-item {
  width: 45%;
  margin-right: 40px;
}
</style>
