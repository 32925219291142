var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmEdit" }, [
    _c(
      "div",
      { staticClass: "search-area" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "demo-form-inline dialog-form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              inline: true,
              "label-width": "126px",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "产生时开启提醒：" }
              },
              [
                _c("el-switch", {
                  attrs: {
                    "active-value": 1,
                    "inactive-value": 0,
                    "inactive-color": "#c7c8c9"
                  },
                  on: { change: _vm.popChange },
                  model: {
                    value: _vm.form.popup,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "popup", $$v)
                    },
                    expression: "form.popup"
                  }
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "inline-block",
                      "margin-left": "12px"
                    }
                  },
                  [
                    _c("span", [_vm._v("提醒方式:")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          disabled: !_vm.form.popup,
                          placeholder: "请选择提醒方式"
                        },
                        model: {
                          value: _vm.form.popupType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "popupType", $$v)
                          },
                          expression: "form.popupType"
                        }
                      },
                      _vm._l(_vm.dictList.upgradeRemindList, function(item) {
                        return _c("el-option", {
                          key: item.dictCode,
                          attrs: { label: item.dictValue, value: item.dictCode }
                        })
                      }),
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.popupType == 3,
                            expression: "form.popupType==3"
                          }
                        ]
                      },
                      [
                        _vm._v(" 输入手机号: "),
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "手机号用逗号隔开" },
                          model: {
                            value: _vm.form.remindPhones,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "remindPhones",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.remindPhones"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticStyle: { width: "100%" }, attrs: { label: "超时时间:" } },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { prop: "timeoutLimit" },
                  model: {
                    value: _vm.form.timeoutLimit,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "timeoutLimit",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.timeoutLimit"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 10px" } }, [
                  _vm._v("分钟未处理，提醒方式")
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.form.timeoutRemind,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "timeoutRemind", $$v)
                      },
                      expression: "form.timeoutRemind"
                    }
                  },
                  _vm._l(_vm.dictList.upgradeRemindList, function(item) {
                    return _c("el-option", {
                      key: item.dictCode,
                      attrs: { label: item.dictValue, value: item.dictCode }
                    })
                  }),
                  1
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.timeoutRemind == 3,
                        expression: "form.timeoutRemind==3"
                      }
                    ]
                  },
                  [
                    _vm._v(" 输入手机号: "),
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "手机号用逗号隔开" },
                      model: {
                        value: _vm.form.timeoutRemindPhones,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "timeoutRemindPhones",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.timeoutRemindPhones"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "是否系统自动处理：" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    on: { change: _vm.changeType },
                    model: {
                      value: _vm.form.disposeType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "disposeType", $$v)
                      },
                      expression: "form.disposeType"
                    }
                  },
                  _vm._l(_vm.automaticDealList, function(item) {
                    return _c("el-option", {
                      key: item.dictCode,
                      attrs: { label: item.dictValue, value: item.dictCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dealShow,
                    expression: "dealShow"
                  }
                ],
                attrs: { label: "处理方式：", prop: "disposeWay" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择" },
                    model: {
                      value: _vm.form.disposeWay,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "disposeWay", $$v)
                      },
                      expression: "form.disposeWay"
                    }
                  },
                  _vm._l(_vm.dictList.disposeWayList, function(item) {
                    return _c("el-option", {
                      key: item.dictCode,
                      attrs: { label: item.dictValue, value: item.dictCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dealShow,
                    expression: "dealShow"
                  }
                ],
                attrs: { label: "报警确认：", prop: "alarmConfirm" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择" },
                    model: {
                      value: _vm.form.alarmConfirm,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "alarmConfirm", $$v)
                      },
                      expression: "form.alarmConfirm"
                    }
                  },
                  _vm._l(_vm.dictList.policeConfirmedList, function(item) {
                    return _c("el-option", {
                      key: item.dictCode,
                      attrs: { label: item.dictValue, value: item.dictCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.dealShow
              ? _c(
                  "el-form-item",
                  {
                    attrs: { label: "自动处理内容：", prop: "disposeContent" }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea" },
                      model: {
                        value: _vm.form.disposeContent,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "disposeContent", $$v)
                        },
                        expression: "form.disposeContent"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }