var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmEdit" }, [
    _c(
      "div",
      { staticClass: "search-area" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "demo-form-inline dialog-form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              inline: true,
              "label-width": "126px",
              "label-position": "left"
            }
          },
          [
            _vm.editData.id
              ? _c(
                  "el-form-item",
                  { attrs: { label: "报警名称：" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.alarmName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "alarmName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.alarmName"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.editData.id
              ? _c(
                  "el-form-item",
                  { attrs: { label: "是否关注此报警：" } },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "inactive-color": "#c7c8c9"
                      },
                      model: {
                        value: _vm.form.attention,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "attention", $$v)
                        },
                        expression: "form.attention"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "是否执行升级策略：" }
              },
              [
                _c(
                  "div",
                  { staticClass: "flx" },
                  [
                    _c("el-switch", {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "inactive-color": "#c7c8c9"
                      },
                      model: {
                        value: _vm.form.upgradeExecute,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "upgradeExecute", $$v)
                        },
                        expression: "form.upgradeExecute"
                      }
                    }),
                    _vm.form.upgradeExecute
                      ? _c("div", { staticStyle: { "margin-left": "16px" } }, [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { prop: "upgradeTime" },
                                model: {
                                  value: _vm.form.upgradeTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "upgradeTime",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.upgradeTime"
                                }
                              }),
                              _vm._v("秒内，连续产生 "),
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.form.upgradeNum,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "upgradeNum",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.upgradeNum"
                                }
                              }),
                              _vm._v("次相同报警时报警升级 ")
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm.form.upgradeExecute
                  ? _c(
                      "div",
                      [
                        _vm._v(" 特殊提醒方式: "),
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "" },
                            model: {
                              value: _vm.form.upgradeRemind,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "upgradeRemind", $$v)
                              },
                              expression: "form.upgradeRemind"
                            }
                          },
                          _vm._l(_vm.dictList.upgradeRemindList, function(
                            item
                          ) {
                            return _c("el-option", {
                              key: item.dictCode,
                              attrs: {
                                label: item.dictValue,
                                value: item.dictCode
                              }
                            })
                          }),
                          1
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.upgradeRemind == 3,
                                expression: "form.upgradeRemind==3"
                              }
                            ]
                          },
                          [
                            _vm._v(" 输入手机号: "),
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "手机号用逗号隔开" },
                              model: {
                                value: _vm.form.remindPhones,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "remindPhones",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.remindPhones"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%", "margin-top": "32px" },
                attrs: { label: "报警等级:" }
              },
              [
                _c("div", { staticClass: "tabs" }, [
                  _c(
                    "span",
                    {
                      class: _vm.activeName === "first" ? "active" : "",
                      on: {
                        click: function($event) {
                          return _vm.handleClick("first")
                        }
                      }
                    },
                    [_vm._v("一级报警")]
                  ),
                  _c(
                    "span",
                    {
                      class: _vm.activeName === "second" ? "active" : "",
                      on: {
                        click: function($event) {
                          return _vm.handleClick("second")
                        }
                      }
                    },
                    [_vm._v("二级报警")]
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c("alarmItem", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "first",
              expression: "activeName==='first'"
            }
          ],
          ref: "item1",
          attrs: {
            itemData: _vm.editData.oneLevelAlarm,
            editId: _vm.editData.id,
            dictList: _vm.dictList,
            dictObj: _vm.dictObj
          }
        }),
        _c("alarmItem", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "second",
              expression: "activeName==='second'"
            }
          ],
          ref: "item2",
          attrs: {
            itemData: _vm.editData.twoLevelAlarm,
            editId: _vm.editData.id,
            dictList: _vm.dictList,
            dictObj: _vm.dictObj
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "buttonGroup text-right" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "default" },
            on: { click: _vm.cancel }
          },
          [_vm._v("取消")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary", loading: _vm.editLoading },
            on: { click: _vm.save }
          },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }