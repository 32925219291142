<template>
  <div class="search form-area">
    <el-form
      :inline="true"
      :model="searchFrom"
      class="demo-form-inline"
      label-position="left"
      label-width="72px"
    >
      <el-form-item label="所属车队：">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>

      <el-form-item label="报警名称：">
        <el-cascader
          v-model="searchFrom.alarmTypeList"
          :options="alarmOptions"
          :props="affirmAlarmProps"
          size="small"
          collapse-tags
          clearable
          filterable
        ></el-cascader>
      </el-form-item>

      <el-form-item label="报警等级：" v-if="isShow">
        <el-select
          clearable
          placeholder="请选择"
          v-model="searchFrom.alarmLevel"
        >
          <el-option
            v-for="item in dictList.alarmLevelList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报警来源：">
        <el-select
          clearable
          v-model="searchFrom.alarmSource"
          placeholder="请选择"
        >
          <el-option
            v-for="item in dictList.alarmSourceList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报警弹窗：" v-if="isShow">
        <el-select clearable v-model="searchFrom.popup" placeholder="请选择">
          <el-option
            v-for="item in dictList.popupList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报警声音：" v-if="isShow">
        <el-select clearable v-model="searchFrom.voice" placeholder="请选择">
          <el-option
            v-for="item in dictList.voiceList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="处理类型：" v-if="isShow">
        <el-select
          clearable
          v-model="searchFrom.disposeType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in dictList.disposeTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="search" size="small" type="primary">查询</el-button>
        <el-button
          @click="batchSet"
          size="small"
          type="primary"
          v-if="$store.state.menu.nowMenuList.indexOf('批量设置') >= 0"
          >批量设置</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import { getBusinessCompanyTree } from '@/api/lib/api.js'

export default {
  components: {
    companySelectForSearch
  },
  props: {
    dictList: {
      type: Object
    },
    alarmOptions: {
      type: Array
    },
    isShow:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      searchFrom: {
        // alarmName: '',
        alarmLevel: '',//报警等级
        popup: '',//报警弹窗
        disposeType: '',//处理类型
        voice: '',//报警声音
        alarmSource: '',
        alarmTypeList: [],
        companyIdList: []
      },
      affirmAlarmProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      },
      companyIdsOptions: [],
      companyList: [],
      valueName: null,
      value: null
    }
  },
  created () {
    this.getCompanyList()
  },

  methods: {
    search () {
      var fromData = null
      if(this.isShow){
        fromData = this.searchFrom;
      }else{
        fromData = {
          alarmSource:this.searchFrom.alarmSource,
          alarmTypeList:this.searchFrom.alarmTypeList,
          companyIdList:this.searchFrom.companyIdList
        }
      }
      this.$emit('sendInfo', fromData)
    },

    batchSet () {
      this.$emit('batchSet')
    },

    getGroupIds (v) {
      this.searchFrom.companyIdList = v
      // this.$emit('sendInfo', this.searchFrom)
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree({ system: sessionStorage.getItem("system") }).then(res => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
      this.search()
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/.search {
  background: rgba(220, 222, 223, 0.1) !important;
}
/deep/.global-alarm {
  padding-bottom: 16px;
}
</style>
