<template>
  <div class="miniTable">
    <el-table
      @selection-change="changeSelect"
      stripe
      :data="tableData"
      height="calc(100vh - 315px)"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="companyName" label="车队" :show-overflow-tooltip="true" width="200px"></el-table-column>
      <el-table-column prop="alarmType" label="报警名称" :show-overflow-tooltip="true" width="200px">
        <template slot-scope="scope">
          <span v-if="scope.row.alarmSpecies === 'D0008'">{{ dictObj.D0008Obj[Number(scope.row.alarmType)] }}</span>
          <span v-if="scope.row.alarmSpecies === 'D0009'">{{ dictObj.D0009Obj[Number(scope.row.alarmType)] }}</span>
          <span v-if="scope.row.alarmSpecies === 'D0010'">{{ dictObj.D0010Obj[Number(scope.row.alarmType)] }}</span>
          </template>
      </el-table-column>
      <el-table-column prop="attention" label="是否关注">
        <template slot-scope="scope">{{ scope.row.attention===1?'是':'否' }}</template>
      </el-table-column>
      <el-table-column prop="alarmSource" label="报警来源">
        <template slot-scope="scope">{{ dictObj.alarmSourceObj[Number(scope.row.alarmSource)] }}</template>
      </el-table-column>     
      <el-table-column
        prop="updateTime"
        label="最后更新时间"
        width="180"
        :show-overflow-tooltip="true"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column prop="userName" label="更新人"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="edit(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
          >设置</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatDate } from '@/common/utils/index'

export default {
  props: {
    tableData: {
      type: Array
    },
    dictObj: {
      type: Object
    },
    alarmAllObj:{
      type: Object
    }
  },
  data () {
    return {}
  },

  methods: {
    edit (row) {
      this.$emit('editTable', row)
    },

    formatTime (row, column) {
      return formatDate(row.updateTime)
    },
    // 切换选中项
    changeSelect (val) {
      this.$emit('changeSelect', val)
    }
  }
}
</script>

<style lang='scss' scoped>
.miniTable {
}
</style>
