<template>
  <div class="alarmEdit">
    <div class="search-area">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="true"
        label-width="126px"
        label-position="left"
        class="demo-form-inline dialog-form"
      >
        <!-- 报警名称 -->
        <el-form-item label="报警名称：" v-if="editData.id">
          <el-input disabled v-model.trim="form.alarmName"></el-input>
        </el-form-item>
        <!-- 是否关注此报警 -->
        <el-form-item label="是否关注此报警：" v-if="editData.id">
          <el-switch
            v-model="form.attention"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#c7c8c9"
          ></el-switch>
        </el-form-item>
        <!-- 是否执行升级策略 0：不执行 1：执行 -->
        <el-form-item label="是否执行升级策略：" style="width:100%;">
          <div class="flx">
            <el-switch
              v-model="form.upgradeExecute"
              :active-value="1"
              :inactive-value="0"
              inactive-color="#c7c8c9"
              style="margin-top:10px;"
            ></el-switch>
            <div v-if="form.upgradeExecute" style="margin-left:16px;">
              <div>
                <el-input v-model.trim="form.upgradeTime" style="width:200px;" prop="upgradeTime"></el-input>秒内，连续产生
                <el-input v-model.trim="form.upgradeNum" style="width:200px;"></el-input>次相同报警时报警升级
              </div>
            </div>
          </div>
          <div v-if="form.upgradeExecute">
            特殊提醒方式:
            <el-select clearable v-model="form.upgradeRemind" placeholder>
              <el-option
                v-for="item in dictList.upgradeRemindList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
            <!-- 输入电话号码 -->
            <span v-show="form.upgradeRemind==3">
              输入手机号:
              <el-input
                v-model.trim="form.remindPhones"
                style="width:200px;"
                placeholder="手机号用逗号隔开"
              ></el-input>
            </span>
          </div>
        </el-form-item>
        <!-- 报警等级 -->
        <el-form-item label="报警等级:" style="width:100%;margin-top:32px;">
          <div class="tabs">
            <span :class="activeName==='first'?'active':''" @click="handleClick('first')">一级报警</span>
            <span :class="activeName==='second'?'active':''" @click="handleClick('second')">二级报警</span>
          </div>
        </el-form-item>
      </el-form>
      <!-- 一级报警配置 -->
      <alarmItem
        ref="item1"
        v-show="activeName==='first'"
        :itemData="editData.oneLevelAlarm"
        :editId="editData.id"
        :dictList="dictList"
        :dictObj="dictObj"
      ></alarmItem>
      <!-- 二级报警配置 -->
      <alarmItem
        ref="item2"
        v-show="activeName==='second'"
        :itemData="editData.twoLevelAlarm"
        :editId="editData.id"
        :dictList="dictList"
        :dictObj="dictObj"
      ></alarmItem>
    </div>
    <div class="buttonGroup text-right">
      <el-button size="small" type="default" @click="cancel">取消</el-button>
      <el-button size="small" type="primary" @click="save" :loading="editLoading">保存</el-button>
    </div>
  </div>
</template>

<script>
import { updateAlarmConfigs } from '@/api/lib/api.js'
import alarmItem from '../components/alarmformItem.vue'

export default {
  components: { alarmItem },
  props: {
    editData: {
      type: Object
    },
    batchList: {
      type: Array
    },
    dictList: {
      type: Object
    },
    dictObj: {
      type: Object
    }
  },
  data () {
    let checkRangeNumber = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (typeof value !== 'number' || value < 0 || value > 1440) {
          callback(new Error('请输入0-1440的整数'))
        } else {
          callback()
        }
      }
    }
    return {
      row: null,
      form: {},
      activeName: 'first',
      rules: {
        upgradeTime: [{ validator: checkRangeNumber, trigger: 'change' }],
        disposeWay: [
          { required: true, message: '请选择处理方式', trigger: 'blur' }
        ],
        alarmDesc: [
          { required: true, message: '请填写处理描述', trigger: 'change' }
        ]
      },
      editLoading: false
    }
  },
  created () { },

  mounted () {
    if (this.editData.id) {
      this.form = { ...this.editData }
      this.form.upgradeRemind += '';//转换成字符串显示
    } else {
      this.form.ids = this.batchList
    }
  },

  methods: {
    handleClick (tab, event) {
      this.activeName = tab;
    },
    changeType () {
      this.form.disposeWay = null
    },
    /* 保存 */
    save () {
      let self = this
      let itemObj = this.$refs.item1.form;
      let itemObj2 = this.$refs.item2.form;
      let flagFalse = true;
      self.$refs.form.validate((valid) => {
        if (valid) {
          if (itemObj.disposeType === '1') {
            self.$refs.item1.$refs.form.validate(vd => {
              if (!vd) {
                flagFalse=false;
                return 
              }
            })
          }
          if (itemObj2.disposeType === '1') {
            self.$refs.item2.$refs.form.validate(vd2 => {
              if (!vd2) {
                flagFalse=false;
                return 
              }
            })
          }
          if (flagFalse) {
            this.editLoading = true
            itemObj.remindPhones = itemObj.popupType == 3 ? itemObj.remindPhones : ''
            itemObj.timeoutRemindPhones = itemObj.timeoutRemind == 3 ? itemObj.timeoutRemindPhones : ''
            itemObj2.remindPhones = itemObj2.popupType == 3 ? itemObj2.remindPhones : ''
            itemObj2.timeoutRemindPhones = itemObj2.timeoutRemind == 3 ? itemObj2.timeoutRemindPhones : ''
            let data = {
              ids: this.form.ids || [this.form.id],
              attention: this.form.attention,
              upgradeExecute: this.form.upgradeExecute,
              upgradeTime: this.form.upgradeTime,
              upgradeNum: this.form.upgradeNum,
              upgradeRemind: this.form.upgradeRemind,
              remindPhones: this.form.upgradeRemind == 3 ? this.form.remindPhones : '',//如果是短信则取输入的手机号，否则取空
              system: sessionStorage.getItem('system').toString(),
              oneLevelAlarm: JSON.stringify(itemObj),
              twoLevelAlarm: JSON.stringify(itemObj2)
            }
            updateAlarmConfigs(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('保存成功')
                this.$emit('submitSuccess', 1)
              } else {
                this.$message.error(res.msg)
              }
              this.editLoading = false
            })
          }

        }

      })
    },
    /* 取消 */
    cancel () {
      this.$emit('submitSuccess', 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form--inline .el-form-item {
  width: 45%;
  margin-right: 40px;
}
.flx {
  display: flex;
  align-items: center;
}
.tabs {
  span {
    display: inline-block;
    width: 140px;
    border-radius: 4px;
    margin-right: 10px;
    background: #aaa;
    color: #333;
    text-align: center;
    cursor: pointer;
  }
  @include themify() {
    .active {
      background: themed('b4');
      color: #fff;
    }
  }
}
.ipt {
  display: inline-block;
  border: 1px solid #000;
  /deep/.el-input__inner {
    width: 100px !important;
  }
}
</style>
